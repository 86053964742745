<script>

import AkLabel from "@components/general/AkLabel";

export default {
  components: {AkLabel},
  inject: {'pSubmitted' : {default: null}, 'pDisabled' : {default: null}},
  props: {
    label: {
      type: String,
      required: false,
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4 col-6"
    },
    inputType: {
      type: String,
      required: false,
      default: "text"
    },
    validator: {
      type: Object,
      required: false
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined
    },
    link: {
      type: Object,
      required: false,
      default: undefined
    },
    displayer: {
      type: Function,
      required: false,
      default: undefined
    },
    regexp:{
      type:String,
      required: false,
    },
    tooltip : {
      type:String
    },
    autocomplete : {
      type:String,
      default: 'on'
    },
  },
  methods: {},
  watch: {
    currentValue(val, oldValue) {
      if(null != this.regexp && null != val){
        if(!val.match(this.regexp)){
          val = oldValue;
          this.$emit('update:modelValue', oldValue);
        }

      }
    }
  },
  computed: {
    currentValue: {
      get() {
        if(this.displayer) return this.displayer(this.modelValue);
        return this.modelValue;
      },
      set(val, oldVal) {

        this.$emit('update:modelValue', val);
      }
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return this.validator !== undefined && this.validator.required !== undefined && this.validator.required.$invalid && this.alreadySubmitted;
    },
    isMinLength() {
      return this.hasValidator && this.validator.minLength !== undefined;
    },
    isValidatorMinLength() {
      return this.isMinLength && this.validator.minLength.$invalid && this.alreadySubmitted;
    },
    isMaxLength() {
      return this.hasValidator && this.validator.maxLength !== undefined;
    },
    isValidatorMaxLength() {
      return this.isMaxLength && this.validator.maxLength.$invalid && this.alreadySubmitted;
    },
    isValidatorEmail() {
      return this.validator !== undefined && this.validator.email !== undefined && this.validator.email.$invalid && this.alreadySubmitted;
    },
    isValidatorPhone() {
      return this.validator !== undefined && this.validator.phoneValidator !== undefined && this.validator.phoneValidator.$invalid && this.alreadySubmitted;
    },
    isValidatorDuplicatedKey() {
      return this.validator !== undefined && this.validator.notUsed !== undefined && this.validator.notUsed.$invalid && this.alreadySubmitted;
    },
  }
}
</script>

<template>
  <div :class="this.className" class="form-group">
    <AkLabel v-if="this.label !== '' && this.label !== undefined" :required="isRequired">{{ this.label }}<i v-if="null !=tooltip" class="fa fa-info-circle tooltip-centered" v-tooltip="tooltip"/></AkLabel>
    <InputText v-model="currentValue" 
        :class="{'p-invalid':isInvalid && alreadySubmitted}" 
        :disabled="isDisabled"
        :placeholder="getPlaceholder"
        :autocomplete="autocomplete"
        :type="inputType"
        class="form-control"/>

    <RouterLink v-if="isDisabled && this.link !== undefined" :to="{name: this.link.route, params: this.link.params}">
      <i class="icon-link" :class="this.link.icon"/>
    </RouterLink>
    <small v-if="isValidatorEmail" class="p-error">{{ $t("bad_format", [label]) }}</small>
    <small v-if="isValidatorPhone" class="p-error">{{ $t("check_phone_number") }}</small>
    <small v-if="isValidatorRequired && alreadySubmitted" class="p-error">{{$t("field_required", [this.label]) }}</small>
    <small v-if="isValidatorMinLength"
           class="p-error">{{ $t("min_length", [this.label, this.validator.minLength.$params.min]) }}</small>
    <small v-if="isValidatorMaxLength"
           class="p-error">{{ $t("max_length", [this.label, this.validator.maxLength.$params.max]) }}</small>
  </div>
</template>